var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-space-between align-space-between w-100",
          },
          [
            _c("span", { staticClass: "secondary--text font-weight-bold" }, [
              _vm._v("Video Library"),
            ]),
            !_vm.quotaLoading
              ? _c(
                  "v-card",
                  { attrs: { flat: "" } },
                  [
                    _c(
                      "v-card-text",
                      [
                        _c("v-progress-linear", {
                          staticClass: "mb-2",
                          attrs: {
                            rounded: "",
                            height: "6",
                            value: _vm.usedQuota,
                            color: _vm.usedQuota <= 90 ? "primary" : "error",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "caption font-weight-medium d-flex align-center",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  `${_vm.usedQuota}% of ${_vm.toHours(
                                    _vm.videoLibraryQuota.limit
                                  )} hours`
                                ) +
                                " used "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("v-card-text", [
        !_vm.loading && _vm.videos.length === 0
          ? _c(
              "div",
              [
                _c(
                  "v-alert",
                  { attrs: { text: "", prominent: "", type: "info" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-center justify-space-between w-100",
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("No videos found"),
                          ]),
                          _c("span", [
                            _vm._v(
                              " So far no videos have been uploaded to the Video Library. "
                            ),
                            _vm.isSpaceAdmin
                              ? _c("span", [
                                  _vm._v(
                                    " You can upload videos to the Video Library by going to Files and uploading a video under Actions. "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _vm.isSpaceAdmin
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  to: {
                                    name: "snapshot-files",
                                    params: {
                                      fileArea:
                                        this.folderTypesLabels.WORKSPACE_FILES,
                                    },
                                  },
                                  outlined: "",
                                  small: "",
                                  color: "info",
                                },
                              },
                              [_vm._v(" Go to files ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-wrap align-center" },
                  [
                    _c("v-spacer"),
                    _c("v-text-field", {
                      staticClass: "mr-2",
                      attrs: {
                        label: "Search",
                        "prepend-inner-icon": "mdi-filter",
                        autofocus: "",
                        "hide-details": "",
                        solo: "",
                        flat: "",
                        "background-color": "grey lighten-4",
                        dense: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                    _c(
                      "v-btn",
                      {
                        attrs: { loading: _vm.loading, icon: "" },
                        on: { click: _vm.fetchVideos },
                      },
                      [_c("v-icon", [_vm._v("refresh")])],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-data-table", {
                  staticClass: "pb-12",
                  attrs: {
                    "show-select": _vm.videos.length > 0,
                    search: _vm.search,
                    "item-key": "uri",
                    headers: _vm.headers,
                    loading: _vm.loading,
                    items: _vm.videos,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "top",
                      fn: function ({ pagination, options, updateOptions }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center justify-end" },
                            [
                              _c(
                                "vimeo-confirm-dialog",
                                {
                                  attrs: {
                                    videos: _vm.selected,
                                    disabled: _vm.selected.length === 0,
                                  },
                                  on: { dismissed: _vm.fetchVideos },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.selected.length === 0,
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("delete"),
                                      ]),
                                      _vm._v(" Delete videos "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-spacer"),
                              _c("v-data-footer", {
                                attrs: {
                                  pagination: pagination,
                                  options: options,
                                  "items-per-page-text":
                                    "$vuetify.dataTable.itemsPerPageText",
                                },
                                on: { "update:options": updateOptions },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "item",
                      fn: function ({ item, index }) {
                        return [
                          _c(
                            "tr",
                            {
                              on: {
                                mouseover: function ($event) {
                                  _vm.videoHover = index
                                },
                              },
                            },
                            [
                              _c(
                                "td",
                                [
                                  _c("v-checkbox", {
                                    staticClass: "pa-0 ma-0",
                                    attrs: {
                                      multiple: "",
                                      value: item,
                                      "hide-details": "",
                                      flat: "",
                                    },
                                    model: {
                                      value: _vm.selected,
                                      callback: function ($$v) {
                                        _vm.selected = $$v
                                      },
                                      expression: "selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("td", [
                                item.is_playable
                                  ? _c("div", {
                                      staticClass: "py-2 preview",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.embedCode(item, "responsive")
                                        ),
                                      },
                                    })
                                  : _c("div", { staticClass: "py-2 preview" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "video-placeholder",
                                          style: _vm.cssVars(item),
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "video-content" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "caption" },
                                                [_vm._v("processing...")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    fab: "",
                                                    small: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: _vm.fetchVideos,
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("refresh"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]),
                              ]),
                              _c("td", [
                                _c(
                                  "div",
                                  { staticClass: "py-2" },
                                  [
                                    _vm.isSpaceArchived
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "secondary--text subtitle-1 font-weight-bold text-decoration-none",
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.name) + " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "v-edit-dialog",
                                          {
                                            attrs: {
                                              "return-value": item.name,
                                            },
                                            on: {
                                              "update:returnValue": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  item,
                                                  "name",
                                                  $event
                                                )
                                              },
                                              "update:return-value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  item,
                                                  "name",
                                                  $event
                                                )
                                              },
                                              save: function ($event) {
                                                return _vm.updateVideoName(item)
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "input",
                                                  fn: function () {
                                                    return [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: "Edit",
                                                          "single-line": "",
                                                          outlined: "",
                                                        },
                                                        model: {
                                                          value: item.name,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "name",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.name",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "secondary--text subtitle-1 font-weight-bold text-decoration-none",
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.name) + " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                    item.description
                                      ? _c(
                                          "div",
                                          { staticClass: "subtitle-2" },
                                          [_vm._v(_vm._s(item.description))]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _c("td", [
                                _c("span", { staticClass: "subtitle-2" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dateTimeToHuman")(
                                          item.created_time
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("td", [
                                _c("span", { staticClass: "subtitle-2" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("durationToHuman")(item.duration)
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("td", [
                                index === _vm.videoHover
                                  ? _c(
                                      "div",
                                      { staticClass: "d-flex justify-end" },
                                      [
                                        _c("vimeo-embed-code-btn", {
                                          attrs: { video: item },
                                        }),
                                        _c(
                                          "vimeo-confirm-dialog",
                                          {
                                            attrs: { videos: [item] },
                                            on: { dismissed: _vm.fetchVideos },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "ml-2 secondary--text",
                                                attrs: {
                                                  icon: "",
                                                  disabled: _vm.loading,
                                                  small: "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("delete")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.selected,
                    callback: function ($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected",
                  },
                }),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }